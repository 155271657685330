import { STRING_KEYS } from "../../constants";

import contactInfo from "./oberland17/contact-info.md";
import howMuchPowerTooMuchText from "./oberland17/how-much-power-too-much.md";
import howMuchPowerTooMuchImageCaption from "./oberland17/how-much-power-too-much-image-caption.md";
import landingProducerInfo2Text from "./oberland17/landing-producer-info-2-text.md";
import marketingMaterialsText from "./oberland17/marketing-materials-text.md";
import plantMarginsText from "./cells-energy/plant-margins-text.md";
import potentialCheckPlantTypeMorePlantsTooltip from "./oberland17/potential-check-plant-type-more-plants-tooltip.md";
import potentialCheckSummaryNextStepsHasRlm from "./oberland17/potential-check-summary-next-steps-has-rlm.md";
import potentialCheckSummaryNextStepsNoRlm from "./oberland17/potential-check-summary-next-steps-no-rlm.md";

export const STRINGS = {
  [STRING_KEYS.COMPANY_LEGAL_NAME]: "17er Oberlandenergie GmbH",
  [STRING_KEYS.CONFIRM_EMAIL_SUCCESS]: "Ihre E-Mail Adresse wurde bestätigt!",
  [STRING_KEYS.CONFIRM_EMAIL_BUTTON]: "Weiter zu Ihren Anlagen",
  [STRING_KEYS.CONSUMER_CONTRACT_ALREADY_CANCELLED]:
    "Haben Sie Ihren bestehenden Vertrag bereits gekündigt?",
  [STRING_KEYS.CONSUMER_CONTRACT_ALREADY_CANCELLED_TOOLTIP]:
    "Läuft Ihre Kündigungsfrist in Kürze ab? Kündigen Sie in einem solchen Fall bitte selbst bei Ihrem aktuellen Versorger, um eine ungewollte Verlängerung Ihres aktuellen Vertrags zu vermeiden! Dazu reicht schon eine kurze E-Mail an den Kundenservice Ihres aktuellen Versorgers.",
  [STRING_KEYS.CONSUMER_CONTRACT_MOVING_TOOLTIP]:
    "Um Ihre Entnahmestelle problemlos Anmeldung zu können, benötigen wir das genaue Datum Ihres Einzugs. Die Ummeldung zum 17er Strommarktplatz ist bis zu 6 Wochen nach Einzugsdatum möglich. Liegt das Datum schon länger zurück, wählen Sie bitte als Grund Versorgerwechsel!",
  [STRING_KEYS.CONSUMER_CONTRACT_YOUR_MIX]: "Ihr Mix",
  [STRING_KEYS.CONSUMER_CONTRACT_CONNECTION_POINT]: "Darunter Abnahmestelle",
  [STRING_KEYS.CONSUMER_CONTRACT_AGB]:
    "Die [allgemeinen Geschäftsbedingungen für die Teilnahme am 17er Strommarktplatz]({agbPdf}) habe ich gelesen und erkläre mich vollumfänglich damit einverstanden.",
  [STRING_KEYS.CONSUMER_CONTRACT_PRIVACY]:
    "Die [Bestimmungen zum Datenschutz]({oberland17PrivacyPdf}) habe ich gelesen und erkläre mich vollumfänglich damit einverstanden.",
  [STRING_KEYS.CONSUMER_CONTRACT_RIGHT_OF_CANCELLATION]:
    "Ich habe die [Widerrufsbelehrung]({rightOfCancellationPdf}) gelesen und verstanden, dass ich ein 14-tägiges Widerrufsrecht habe.",
  [STRING_KEYS.CONSUMER_CONTRACT_SEPA_TEXT]:
    "Der Kontoinhaber ermächtigt die 17er Oberlandenergie GmbH Zahlungen aus diesem Vertragsverhältnis von dem angegebenen Konto mittels Lastschrift einzuziehen. Zugleich weist der Kontoinhaber sein Kreditinstitut an, die vom Lieferanten auf das angegebene Konto gezogenen Lastschriften einzulösen.\n\nHinweis: Der Kontoinhaber kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit seinem Kreditinstitut vereinbarten Bedingungen. Die Gläubiger-Identifikationsnummer lautet DE97ZZZ00001798640. Die Mandatsreferenznummer für dieses SEPA-Mandat wird dem Kontoinhaber gesondert mitgeteilt.",
  [STRING_KEYS.CONSUMER_CONTRACT_USE_NAME_IN_PRODUCER_NOTIFICATION]:
    "Ich bin damit einverstanden, dass die 17er Oberlandenergie GmbH, die von mir im Rahmen dieses Vertrages erhobenen Daten (Name, Anlagenauswahl, Höhe des Betreiberzuschuss), an die Betreiber aus meinem Anlagenmix weiterleiten. Ich kann meine Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Der Widerruf berührt nicht die Rechtmäßigkeit der bis zum Widerruf verarbeiteten Daten.\n\nHier halten Sie die Datenschutzinformation nach Art. 13, 14 Datenschutz-Grundverordnung (DSGVO).",
  [STRING_KEYS.CONSUMER_CONTRACT_SUCCESS_MESSAGE]:
    " Herzlichen Glückwunsch! Sie haben sich Ihren persönlichen Mix gesichert. Eine Auftragsbestätigung haben wir Ihnen bereits per E-Mail zugeschickt.\n\nWilkommen Dahoam auf dem 17er Strommarktplatz!",
  [STRING_KEYS.CONSUMER_CONTRACT_DELIVERY_START_DATE]:
    "Startdatum Belieferung Marktplatz",
  [STRING_KEYS.CONTACT_MESSAGE_LABEL]: "Ihre Nachricht",
  [STRING_KEYS.CONTACT_INFO]: contactInfo,
  [STRING_KEYS.CONTACT_PRIVACY]:
    "Mit dem Absenden einer Anfrage erklären Sie, dass Sie mit der Nutzung der personenbezogenen Daten gem. unserer [Datenschutzerklärung](https://17er.com/datenschutz) einverstanden sind.",
  [STRING_KEYS.CONTACT_SUCCESS]: "Ihre Nachricht wurde erfolgreich gesendet.",
  [STRING_KEYS.CREATE_MIX_TITLE]: "Wählen Sie Ihren Mix",
  [STRING_KEYS.CREATE_MIX_INTRO]:
    "Unser automatischer Marktplatz-Agent hat Ihnen schon einen Mix von Anlagen in Ihrer Nähe erstellt. Passen Sie die Auswahl an, indem Sie die Einstellungen bei „aktueller Mix“ verändern. Sie können auch direkt auf der Karte oder der Liste darunter die Erzeuger Ihres Vertrauens auswählen. Ganz einfach!",
  [STRING_KEYS.CREATE_MIX_CHOOSE_PLANT]:
    "Bitte wählen Sie einen Lieranten für Ihren Mix.",
  [STRING_KEYS.CREATE_MIX_MIX_CHART_TITLE_1]: "Das können Ihre",
  [STRING_KEYS.CREATE_MIX_MIX_CHART_TITLE_2]: "Betreiber liefern:",
  [STRING_KEYS.EDIT_POWER_PLANT_TITLE]:
    "Erstellen Sie das Profil für Ihre Anlage ",
  [STRING_KEYS.EDIT_POWER_PLANT_NAME_INFO]:
    "Wählen Sie einen greifbare, aussagekräftigen Namen.",
  [STRING_KEYS.EDIT_POWER_PLANT_NAME_INFO_TOOLTIP]:
    "Nutzen Sie regionale Ortsbezeichnungen und Besonderheiten. „Windfarm Heiligenfeld“ transportiert viel mehr Emotionen als „Windpark A7 Nord“ und „Bioenergie und Nahwärme Huber“ klingt deutlich runder als „Biogasanlage Huber“.",
  [STRING_KEYS.EDIT_POWER_PLANT_DESCRIPTION_INFO]:
    "Wenn Ihr Kunde von Namen und Bildern angesprochen wurde, möchte er wissen, was er bei Ihnen bekommt. Heben Sie ein besonderes Engagement hervor. Zeigen Sie sich authentisch und menschlich. Sagen Sie, was Ihnen wichtig ist und was Ihr Kunde unterstützt, wenn er Ihren Investitionsbonus bezahlt. Pioniergeist, Ökoogie und Idealismus sind wichtige Merkmale für Ihren Erfolg!\n\nSie sind nicht gut darin, Texte zu verfassen? Wir helfen Ihnen, das optimale Konzept für Ihre Außendarstellung zu erarbeiten.",
  [STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_1]:
    "Erstellen Sie professionelle Fotos ",
  [STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_1_TOOLTIP]:
    "Werbung und Hochglanz gehören untrennbar zusammen. Um Erfolg zu haben, benötigen Sie eine professionelle Selbstdarstellung. Wir empfehlen Ihnen, einmalig ein paar Euro für einen Fotografen auszugeben, dann wird Ihr Anlagenprofil die besten Ergebnisse bringen! Und wenn Sie noch eins drauf setzen wollen, empfehlen wir ein professionelles Dronenvideo!",
  [STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_2]:
    "Zeigen Sie sich als Betreiber",
  [STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_2_TOOLTIP]:
    "Es ist entscheidend für Ihren Erfolg bei der Kundenakquise, dass Sie sich und Ihre Anlage optimal darstellen. Wichtig ist dem Kunden der Bezug zu realen Personen. Also scheuen Sie sich nicht davor, sich selbst fotografieren zu lassen!",
  [STRING_KEYS.EDIT_POWER_PLANT_PROFILE_VISIBILITY_TITLE]:
    "Sichtbarkeit Ihre Profils?",
  [STRING_KEYS.EDIT_POWER_PLANT_NOT_APPROVED_WARNING]:
    "Wir würden gerne auch noch einen kurzen Blick auf Ihr Profil werfen, bevor es veröffentlicht wird. Wir lassen es Sie wissen, sobald es veröffentlicht werden kann!",
  [STRING_KEYS.EDIT_POWER_PLANT_HELP_MODAL_TITLE]: "Brauchen Sie Hilfe?",
  [STRING_KEYS.EDIT_POWER_PLANT_HELP_MODAL_TEXT]:
    "Benötigen Sie eine Idee, wie Ihr Profil aussehen könnte? Hier ein Beispiel:  [dieses Beispielprofil](https://marktplatz.cells.energy/strom-kaufen/anlagen/85ga8/bioenergie-und-nahwarme-karrer-woringen) an!",
  [STRING_KEYS.ERROR_FIRST_NAME_MISSING]: "Bitte geben Sie einen Vornamen ein.",
  [STRING_KEYS.ERROR_LAST_NAME_MISSING]: "Bitte geben Sie einen Nachnamen ein.",
  [STRING_KEYS.ERROR_TITLE_MISSING]: "Bitte wählen Sie eine Anrede.",
  [STRING_KEYS.ERROR_EMAIL_MISSING]: "Bitte geben Sie eine E-Mail-Adresse ein.",
  [STRING_KEYS.ERROR_EMAIL_INVALID]:
    "Bitte geben Sie eine gültige E-Mail Adresse ein.",
  [STRING_KEYS.ERROR_PASSWORD_MISSING]:
    "Bitte geben Sie ein gültiges Passwort ein.",
  [STRING_KEYS.ERROR_PASSWORD_NO_MATCH]: "Beide Passwörter müssen stimmen.",
  [STRING_KEYS.ERROR_STREET_MISSING]: "Bitte geben Sie eine Straße ein.",
  [STRING_KEYS.ERROR_CITY_MISSING]: "Bitte geben Sie einen Ort ein.",
  [STRING_KEYS.ERROR_PHONE_MISSING]: "Bitte geben Sie eine Telefonnummer ein.",
  [STRING_KEYS.ERROR_COMPANY_MISSING]:
    "Bitte geben Sie den Namen Ihrer Firma ein.",
  [STRING_KEYS.ERROR_CONTRACT_OWNER_MISSING]:
    "Bitte geben Sie den Namen des Besitzers ein.",
  [STRING_KEYS.ERROR_CONTRACT_STREET_MISSING]:
    "Bitte geben Sie die Straße des Besitzers ein.",
  [STRING_KEYS.ERROR_CONTRACT_CITY_MISSING]:
    "Bitte geben Sie den Ort des Besitzers ein.",
  [STRING_KEYS.ERROR_CONTRACT_PHONE_MISSING]:
    "Bitte geben Sie die Telefonnummer des Besitzers ein.",
  [STRING_KEYS.ERROR_CONTRACT_EMAIL_MISSING]:
    "Bitte hinterlegen Sie eine gültige E-Mail Adresse.",
  [STRING_KEYS.ERROR_PLANT_TYPE_MISSING]: "Bitte wählen Sie einen Anlagetyp.",
  [STRING_KEYS.ERROR_INSTALLED_CAPACITY_MISSING]:
    "Bitte geben Sie die Leistung der Anlage ein.",
  [STRING_KEYS.ERROR_YEARLY_ENERGY_MISSING]:
    "Bitte geben Sie die Energiemenge pro Jahr ein.",
  [STRING_KEYS.ERROR_PLANT_STREET_MISSING]:
    "Bitte geben Sie die Straße der Anlage ein.",
  [STRING_KEYS.ERROR_PLANT_CITY_MISSING]:
    "Bitte geben Sie den Ort der Anlage ein.",
  [STRING_KEYS.ERROR_MARKET_LOCATION_ID_INVALID]:
    "Bitte geben Sie eine gültige Marktlokation-ID der Anlage ein. Wenn die Marktlokation-ID gerade nicht vorlieft, kann das Feld auch leer gelassen werden.",
  [STRING_KEYS.ERROR_DMSP_MISSING]:
    "Bitte geben Sie den aktuellen Direktvermarkter der Anlage ein.",
  [STRING_KEYS.ERROR_MARGIN_MISSING]:
    "Bitte geben Sie Ihren gewünschten Investitionsbonus ein.",
  [STRING_KEYS.ERROR_IBAN_MISSING]: "Bitte geben Sie Ihre IBAN ein.",
  [STRING_KEYS.ERROR_BIC_MISSING]: "Bitte geben Sie die BIC Ihre Bank ein.",
  [STRING_KEYS.ERROR_BANK_STREET_MISSING]:
    "Bitte geben Sie die Straße des Kontoinhabers ein.",
  [STRING_KEYS.ERROR_BANK_CITY_MISSING]:
    "Bitte geben Sie den Ort des Kontoinhabers ein.",
  [STRING_KEYS.ERROR_START_DATE_MISSING]: "Bitte geben Sie ein Startdatum ein.",
  [STRING_KEYS.ERROR_METER_NUMBER_MISSING]:
    "Bitte geben Sie Ihre Zählernummer ein.",
  [STRING_KEYS.ERROR_METER_COUNT_MISSING]:
    "Bitte geben Sie Ihren Zählerstand ein.",
  [STRING_KEYS.ERROR_REASON_FOR_CHANGE_MISSING]:
    "Bitte wählen Sie den Grund für Ihren Wechsel.",
  [STRING_KEYS.ERROR_CURRENT_SUPPLIER_MISSING]:
    "Bitte geben Sie Ihren aktuellen Stromversorger ein.",
  [STRING_KEYS.ERROR_CUSTOMER_NUMBER_MISSING]:
    "Bitte geben Sie Ihre Kundennummer ein.",
  [STRING_KEYS.ERROR_AGREEMENT_MISSING]:
    "Wir benötigen hier bitte Ihr Einverständnis.",
  [STRING_KEYS.ERROR_NO_OPTION_SELECT]: "Bitte wählen Sie eine Option.",
  [STRING_KEYS.ERROR_MESSAGE_MISSING]: "Bitte schreiben Sie eine Nachricht.",
  [STRING_KEYS.ERROR_INVALID_DATA]: "Fehler! Bitte überprüfen Sie Ihre Daten.",
  [STRING_KEYS.ERROR_REPORT_TEXT]:
    "Bitte schreiben Sie [info@cells.energy](mailto:info@cells.energy) mit diesem Fehler: {submitErrors}",
  [STRING_KEYS.ERROR_GENERAL_CONTACT]:
    "Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie [info@cells.energy](mailto:info@cells.energy) für weitere Informationen.",
  [STRING_KEYS.ERROR_GENERAL_RETRY]:
    "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch mal.",
  [STRING_KEYS.ERROR_UNKNOWN]:
    "Unbekannter Fehler! Bitte schreiben Sie an [info@cells.energy](mailto:info@cells.energy) für Hilfe.",
  [STRING_KEYS.EXTENDED_MIX_INFO_TAB_OVERVIEW]: "Mixübersicht",
  [STRING_KEYS.EXTENDED_MIX_INFO_TAB_PRODUCERS]: "Stromlieferanten",
  [STRING_KEYS.EXTENDED_MIX_INFO_CONTRACT_DURATION_TITLE]: "Mindestlaufzeit",
  [STRING_KEYS.EXTENDED_MIX_INFO_CONTRACT_DURATION]: "bis 31.12.{currentYear}",
  [STRING_KEYS.EXTENDED_MIX_INFO_MIN_CONTRACT_DURATION]: "12 Monate",
  [STRING_KEYS.EXTENDED_MIX_INFO_NOTICE_PERIOD]:
    "4 Wochen zum 31.12.{currentYear}",
  [STRING_KEYS.EXTENDED_MIX_INFO_TOTAL_PRICE_PER_YEAR]: "Gesamtpreis pro Jahr",
  [STRING_KEYS.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH]: "Preis pro Monat",
  [STRING_KEYS.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH_HELP_TEXT]:
    "Bitte beachten Sie, der Preis pro Monat ist auf 12 Monate gerechnet. Die Abschlagsermittlung erfolgt auf Basis von 11 Abschlagszahlungen pro Jahr.",
  [STRING_KEYS.FACEBOOK_URL]: "https://de-de.facebook.com/Oberlandenergie/",
  [STRING_KEYS.FULL_MIX]:
    "Ihr Mix ist bereits voll. Soll die Anlage an Position 5 Ihres Mixes ersetzt werden?",
  [STRING_KEYS.GET_A_QUOTE_PLZ]: "Ihre PLZ",
  [STRING_KEYS.GET_A_QUOTE_PLZ_NO_SUPPLY]:
    "Zur Zeit ist im gewählten Gebiet leider keine Belieferung möglich.\n\nStromtarife in Ihrer Region finden Sie auf unserem Partner-Marktplatz [cells energy](https://marktplatz.cells.energy/).",
  [STRING_KEYS.GET_A_QUOTE_PLZ_INVALID]:
    "Bitte hinterlegen Sie eine gültige Postleitzahl.",
  [STRING_KEYS.HOW_MUCH_POWER_PRIVATE_TITLE]:
    "Wie viele Personen wohnen in Ihrem Haushalt?",
  [STRING_KEYS.HOW_MUCH_POWER_BUSINESS_TITLE]: "Wie viel Strom brauchen Sie?",
  [STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH]: howMuchPowerTooMuchText,
  [STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH_IMAGE]: "{partnerImage}",
  [STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH_IMAGE_CAPTION]:
    howMuchPowerTooMuchImageCaption,
  [STRING_KEYS.IMPRESSUM_URL]: "https://17er.com/impressum",
  [STRING_KEYS.INSTAGRAM_URL]:
    "https://www.instagram.com/17er.oberlandenergie/?hl=de",
  [STRING_KEYS.LANDING_CONSUMER_TITLE]:
    "Entscheiden Sie selbst, wer Ihren Strom produziert!",
  [STRING_KEYS.LANDING_CONSUMER_SUBTITLE]:
    "Gemeinsam unterstützen wir Stromerzeuger aus unserer Umgebung. #StromvonDahoam",
  [STRING_KEYS.LANDING_CONSUMER_MAP_TITLE]:
    "Unsere Stromerzeuger aus der Umgebung",
  [STRING_KEYS.LANDING_CONSUMER_MAP_TEXT]:
    "Sie möchten als erneuerbarer Stromproduzent am Marktplatz teilnehmen? Dann klicken Sie [hier](/strom-verkaufen)!",
  [STRING_KEYS.LANDING_CONSUMER_BENEFITS_TITLE]:
    "Unsere 17er Strommarktplatz Vorteile",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_1_TITLE]: "Gut fürs Klima",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_1_TEXT]:
    "Auf unserer Stromplattform finden Sie regionale Anlagenbetreiber von nachhaltigen Solar-, Windkraft-, Wasserkraft- oder Biogasanlagen. Mit der Auswahl Ihres Anlagen-Mix legen Sie fest, welche Erzeuger Sie mit einem Betreiberzuschuss direkt fördern. Diese machen damit, was sie am besten können – neue Anlagen bauen und die bestehenden Anlagen dauerhaft fit halten. Echter #StrommitZukunft eben.",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_2_TITLE]: "Strom direkt von DAHOAM",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_2_TEXT]:
    "Seit Jahren leisten wir, mit unserem Ökostrom der zu 100% aus Wasserkraft vom Walchensee stammt, einen aktiven Beitrag zur Energiewende. Das geht uns aber noch nicht weit genug. Uns liegt unsere Region, das Oberland am Herzen. Wir wollen die Schönheit unserer Heimat schützen. Deshalb tun wir noch mehr und unterstützen die regionale Wertschöpfung. Mit Ihrem persönlichen Anlagen-Mix über unseren 17er Strommarktplatz können Sie - Ihren Nachbar mit der Photovoltaik-Anlage, den örtlichen Landwirt mit der Biogasanlage oder kommunale PV-Anlagen und Windkraftprojekte fördern. Werden Sie ein Teil von uns und sagen klimaschädlichen Kohlekraftwerken den Kampf an. Echter #StromvonDahoam eben.",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_3_TITLE]: "Absolut transparent",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_3_TEXT]:
    "Auf Ihrer Stromrechnung sehen Sie, welche erneuerbaren Stromproduzenten Sie konkret unterstützen. Somit können Sie sich sicher sein, dass der Mehrbetrag, den Sie für Ihren Strom bezahlen, direkt bei den Stromerzeugern ankommt. Wir finden, das ist ein direkter Weg, die Energiewende voranzubringen.",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_4_TITLE]: "Voll flexibel",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_4_TEXT]:
    "Sie können Ihren Vertrag jährlich kündigen oder einen neuen Mix von erneuerbaren Stromproduzenten, die Sie unterstützen möchten wählen.",
  [STRING_KEYS.LANDING_CONSUMER_FOOTER]:
    "Haben wir Sie überzeugt? Dann nutzen Sie unseren #StromvonDahoam!",
  [STRING_KEYS.LANDING_CONSUMER_FOOTER_BUTTON]: "Jetzt Wechseln",
  [STRING_KEYS.LANDING_CONSUMER_HOW_IT_WORKS_TITLE]:
    "Werden Sie Teil der regionalen Wertschöpfung!",
  [STRING_KEYS.LANDING_CONSUMER_HOW_IT_WORKS_TEXT]:
    "Über den 17er Strommarktplatz bringen wir Verbraucher und Stromproduzenten zusammen. Sie als Verbraucher bekommen die Möglichkeit, sich einen ganz persönlichen Anlagen-Mix aus lokalen Anlagen zusammenzustellen und Produzenten direkt zu unterstützen. Als Produzent können Sie mit wenigen Klicks ein Profil anlegen, um Ihren Überschussstrom zu verkaufen. Und immer, wenn Ihr Eigenstrom nicht ausreicht, können Sie ganz einfach Bezugsstrom über uns zurückerwerben. So entsteht ein geschlossener Kreislauf.",
  [STRING_KEYS.LANDING_PRODUCER_ANLAGEN_CHECK_CAPTION]:
    "Prüfen Sie mit ein paar Klicks, ob Ihre Anlage für den Marktplatz geeignet ist!",
  [STRING_KEYS.LANDING_PRODUCER_INFO_1_TITLE]: "Direktvermarktung Plus!",
  [STRING_KEYS.LANDING_PRODUCER_INFO_1_TEXT]:
    "Sie sind Betreiber einer EEG-Anlage und möchten Ihren Überschussstrom auf direktem Wege vermarkten. Über den 17er Strommarktplatz kommt Ihr Strom ganz einfach zum Endverbraucher.",
  [STRING_KEYS.LANDING_PRODUCER_INFO_2_TEXT]: landingProducerInfo2Text,
  [STRING_KEYS.LANDING_PRODUCER_INFO_2_TITLE]:
    "100% sicher und 100% transparent",
  [STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE]:
    "AUS DER REGION - FÜR DIE REGION.",
  [STRING_KEYS.LANDING_PRODUCER_INFO_6_TITLE]:
    "Machen Sie mit beim 17er Strommarktplatz",
  [STRING_KEYS.LANDING_PRODUCER_INFO_6_TEXT]:
    "Wir - die 17er Oberlandenergie sind ein Zusammenschluss der Gemeindewerke Murnau und den Stadtwerken Bad Tölz, Geretsried, Penzberg und Wolfratshausen sowie 23 Gemeinden im Oberland – bauen gemeinsam mit [renergie Allgäu e.V.](http://www.renergie-allgaeu.de) den 17er Strommarktplatz ständig weiter aus mit dem Ziel, eine regionale Stromplattform für jedermann zu erschaffen. Tragen Sie mit Ihrer Anlage einen Teil dazu bei und machen auch Sie mit.",
  [STRING_KEYS.LANDING_PRODUCER_FOOTER]:
    "Haben wir Sie überzeugt? Dann melden Sie Ihre Anlage an!",
  [STRING_KEYS.LOGIN_TITLE]: "Betreiber login",
  [STRING_KEYS.LOGIN_FORGOT_PASSWORD_TITLE]: "Passwort vergessen",
  [STRING_KEYS.LOGIN_FORGOT_PASSWORD_REQUESTED]:
    "Die E-Mail zum Zurücksetzen des Passwortes wurde verschickt.",
  [STRING_KEYS.LOGIN_SUBMIT_BUTTON]: "Login",
  [STRING_KEYS.MARKETING_MATERIALS_MODAL_TITLE]:
    "Wir helfen Ihnen, Ihre Anlagen zu vermarkten!",
  [STRING_KEYS.MARKETING_MATERIALS_TEXT_LONG]:
    "Wir helfen Ihnen, Ihre Anlagen zu vermarkten! " + marketingMaterialsText,
  [STRING_KEYS.MARKETING_MATERIALS_TEXT]: marketingMaterialsText,
  [STRING_KEYS.MENU_ABOUT]: "Über uns - 17er Oberlandenergie",
  [STRING_KEYS.MENU_ABOUT_LINK]: "https://17er.com/ueber-uns",
  [STRING_KEYS.MENU_FAQ]: "Fragen?",
  [STRING_KEYS.MENU_FAQ_LINK]: "https://17er.com/faq",
  [STRING_KEYS.MENU_STROM_KAUFEN]: "Jetzt wechseln",
  [STRING_KEYS.PLANT_MARGINS_TEXT]: plantMarginsText,
  [STRING_KEYS.POTENTIAL_CHECK_PLANT_TYPE_TITLE]: "Daten zu deiner Anlage",
  [STRING_KEYS.POTENTIAL_CHECK_PLANT_TYPE_MORE_PLANTS_TOOLTIP]:
    potentialCheckPlantTypeMorePlantsTooltip,
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_TITLE]:
    "Wählen Sie Ihren aktuellen Direktvermarkter",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_BUTTON]: "Weitere Vermarkter",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_BUTTON]:
    "Ich habe noch keinen Vermarkter",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_SUBHEADER]:
    "Weitere Vermarkter:",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_MODAL_LINK]:
    "https://www.renergie-allgaeu-pool.de/",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_MODAL]:
    "Um auf dem 17er Strommarktplatz zu vermarkten, können Sie in der Regel bei Ihrem Vermarkter bleiben. Bei unseren Markplatzpartnern erhalten Sie besonders gute Konditionen und geprüfte Verträge mit neutraler Betreuung durch renergie Allgäu e.V. im Pool.",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_MODAL]:
    "Bei unseren Marktplatzpartnern erhalten Sie besonders gute Konditionen und geprüfte Verträge mit neutraler Betreuung durch renergie Allgäu e.V. im Pool.",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_MESSAGE]:
    "In diesem Fall, sagen Sie uns bitte:",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_TITLE]:
    "Wählen Sie Ihren Investitionsbonus",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_HOW_GET_TOOLTIP_TEXT]:
    "Wir nehmen an, dass Sie zu bis zu 80% Ihres Stroms auf dem Marktplatz verkaufen können. Es wird aber etwas dauern, das zu erreichen! Viele Betreiber machen mit und der Absatzmarkt ist erst in der Entstehung. Investieren Sie jetzt in Ihre Zukunft und nutzen Sie Ihren Vorsprung, sobald es richtig los geht!",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHAT_IS_BONUS_TOOLTIP_TEXT]:
    "Solange Sie (noch) eine EEG-Vergütung erhalten, dürfen Sie gesetzlich den Strom nicht an Endkunden verkaufen. Darum liefern wir übergangsweise eine Ersatzlieferung nach dem deutschen Strommix (Graustrom). Obendrauf leistet Ihr Kunde freiwillig einen Investitionsbonus. Der ist dazu bestimmt, dass Sie Ihre Anlage dauerhaft fit halten. Denn sobald Ihr EEG-Vergütungszeitraum endet, möchten Ihre Kunden regionalen Grünstrom von Ihnen erhalten.",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHY_LIMIT_TOOLTIP_TEXT]:
    "Wir haben den Investitionsbonus auf 2ct/kWh begrenzt, denn das ist für Endkunden schon an der Schmerzgrenze. Wir empfehlen Ihnen, zu Beginn auf 0,3 ct/kWh oder weniger zu gehen. Erstes Ziel ist viele Kunden zu finden und das geht besser wenn es günstiger ist.",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHY_TWENTY_TOOLTIP_TEXT]:
    "Wenn Ihre 20 Jahre EEG um sind, haben Sie keine Zeit mehr, Kunden zu suchen. Denn das geht nicht so schnell und Sie müssen für den Weiterbetrieb Investitionssicherheit haben. Darum nutzen Sie die Zeit und lernen die Kunst, Ihr Konzept und Ihren Strom zu vermarkten. Und nebenbei helfen Ihnen die Zusatzerlöse, die Sie jetzt schon erhalten, um die Anlage fit zu halten!",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_YES_DMSP]:
    "Glückwunsch! Ihre Anlage sollte bestens geeignet sein, um am 17er Strommarktplatz teilzunehmen.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_HAS_RLM]:
    "Glückwunsch! Ihre Anlage erfüllt einige wichtige Voraussetzungen für die Teilnahme am 17er Strommarktplatz. Die Vermarktung über den Marktplatz bietet bereits kurzfristig einen finanziellen Mehrwert - trotz noch erforderlicher Investitionen.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_NO_RLM]:
    "Glückwunsch! Ihre Anlage hat gute Voraussetzungen für die Teilnahme am 17er Strommarktplatz. Die Vermarktung über den Marktplatz bietet bereits kurzfristig einen finanziellen Mehrwert - trotz noch erforderlicher Investitionen. Sie sollten aber noch einige Formalitäten vorab erledigen. Die jährlichen Kosten dafür sollten unter dem erwarteten Mehrerlös liegen.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_NO_VALUE]:
    "Es geht beim 17er Strommarktplatz zwar nicht allein um Gewinne, aber für Ihre Anlage scheinen die Chancen auf einen kurzfristigen finanziellen Mehrwert noch gering. Ausschlaggebend sind (hauptächlich) für diese Vermarktungsart erforderliche Investitionen in die Anlageinfrastruktur.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_PARTNER_DMSP]:
    "Melden Sie sich jetzt an und gestalten Ihr Anlageprofil! Ein paar Angaben im Formular auf der nächsten Seite sind alles, was wir dafür von Ihnen benötigen.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_OTHER_DMSP]:
    "Melden Sie sich jetzt an und gestalten Sie Ihr Anlagenprofil! Ein paar Angaben im Formular auf der nächsten Seite sind alles, war dafür benötigen. Wir haben festgestellt, dass Ihr aktueller Direktvermarkter noch keine Kooperation mit uns vereinbart hat. Diese Vereinbarung ist für eine reibungslose Abwicklung der energiewirtschaftlichen Prozesse notwendig. Wir setzen uns nach Ihrer Anmeldung umgehend mit {plantDirektMarketer} in Verbindung und regeln das für Sie.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_HAS_RLM]:
    potentialCheckSummaryNextStepsHasRlm,
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_RLM]:
    potentialCheckSummaryNextStepsNoRlm,
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE]:
    "Wir empfehlen Ihnen also, mit der Teilnahme am Marktplatz noch zu warten. Lassen Sie uns einfach Ihre E-Mail Adresse da! Wir speichern Ihre Eingabe und informieren Sie, sobald wir eine für Sie wirtschaftliche Lösung anbieten können.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE_INFO]:
    "Wenn Sie uns erlauben, Ihre eingegebenen Daten zu speichern, kennen wir Ihre Anlagenkonstellation. Wir arbeiten dann mit Hochdruck daran, günstigere Lösungen zu erarbeiten, zum Beispiel für Mess- oder Fernsteuerkonzepte. Und wir wirken mit unserem Partner, renergie Allgäu e.V. auf die Politik ein, um Ihnen einen Weg in die Teilhabe am Energiemarkt der Zukunft zu ebnen. Also lassen Sie sich nicht von Ihrem Ziel abbringen! Registrieren Sie sich unverbindlich und machen Sie mit, beim Bau unseres gemeinsamen Marktplatzes - aus der Region für die Region!",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE_INFO_TITLE]:
    "Warum sollten Sie mit uns in Kontakt bleiben?",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_CONFIRMATION_MODAL_TEXT]:
    "Erst wenn Sie Ihren Direktvermarkter hinzugefügt haben, können Sie Strom über den Marktplatz verkaufen!",
  [STRING_KEYS.PRIVACY_URL]: "https://17er.com/datenschutz",
  [STRING_KEYS.PRODUCER_CONTRACT_INTRO]: `Glückwunsch! Sie sind kurz davor, Ihre Vermarktung an Endkunden zu beginnen! Wir brauchen nur noch ein paar Daten von Ihnen, um Ihr Kundenkonto anlegen zu können. Sofort nach dem Klick auf "Vertrag abschließen" wird Ihre Vermarktungsplattform frei geschalten.`,
  [STRING_KEYS.PRODUCER_CONTRACT_INTRO_NEW_PLANT]:
    "Bitte tragen Sie hier Ihre Daten ein.",
  [STRING_KEYS.PRODUCER_CONTRACT_AGB]:
    "Die [allgemeinen Geschäftsbedingungen für die Teilnahme am cells energy Marktplatz]({agbPdf}) habe ich gelesen und erkläre mich vollumfänglich damit einverstanden.",
  [STRING_KEYS.PRODUCER_CONTRACT_PRIVACY]:
    "Die [Bestimmungen zum Datenschutz der Handelsplattform cells energy](https://marktplatz.cells.energy/privacy) habe ich gelesen und erkläre mich vollumfänglich damit einverstanden.",
  [STRING_KEYS.PRODUCER_CONTRACT_RIGHT_OF_CANCELLATION]:
    "Ich habe die [Widerrufsbelehrung der Handelsplattform cells energy]({rightOfCancellationPdf}) gelesen und verstanden, dass ich ein 14-tägiges Widerrufsrecht habe.",
  [STRING_KEYS.PRODUCER_CONTRACT_SEPA_TEXT]:
    "Ich ermächtigen die Renergie GmbH (Gläubiger-Identifikationsnummer: DE49ZZZ00002138810), Zahlungen aus diesem Auftragsverhältnis von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die vom Lieferanten auf mein Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Die Mandatsreferenznummer für dieses SEPA-Mandat wird dem Kundengesondert mitgeteilt.",
  [STRING_KEYS.PRODUCER_CONTRACT_SUCCESS]:
    "Glückwunsch! Ihr Kundenkonto wurder erflogreich angelegt!",
  [STRING_KEYS.PRODUCER_CONTRACT_SUCCESS_NO_DMSP]:
    "Es sind nur noch einige Kleinigkeiten zu erledigen, bevor Sie mit der Vermarktung starten können. Sie brauchen noch einen Direktvermarkter, um mit dem Verkauf loslegen zu können. Geprüfte Verträge und unabhängige Unterstützung sowie die Auswahl zwischen 5 der wichtigsten Direktvermarkter finden Sie im renergie Anlagen Pool. [link] Folgen Sie dem Link und sobald der Vertrag mit Ihrem Vermarkter abgeschlossen ist, kommen Sie hierher zurück. Wir schicken Ihnen zur Erinnerung den Link auf diese Seite per E-Mail.",
  [STRING_KEYS.PRODUCER_CONTRACT_SUCCESS_PLANT_BUTTON]: "Ihre Anlage",
  [STRING_KEYS.PRODUCER_CONTRACT_ERROR_ACCOUNT_NUMBER]:
    "Wenn Sie die Nummer eintragen, erleichtern Sie Ihrem Direktvermarkter die Zuordnung. Das kann die Anmeldung beschleunigen.",
  [STRING_KEYS.RESET_PASSWORD_TEXT]: "Bitte geben Sie ein neues Passwort ein.",
  [STRING_KEYS.STAY_IN_CONTACT_SUCCESS]:
    "Danke! Bitte prüfen Sie Ihre E-Mails.",
  [STRING_KEYS.CONSUMER_CONTRACT_SUBMIT]: "Vertrag jetzt abschließen",
  [STRING_KEYS.WHY_POWER_TITLE]: "Wofür brauchen Sie Strom?",
  [STRING_KEYS.WIDERRUFSBELEHRUNG_TEXT]: "Widerrufsbelehrung",
  [STRING_KEYS.YOUR_MIX]: "Ihr Mix"
};
